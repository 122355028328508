<template>
    <div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">

            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering"></td>
                </template>

                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('clear_all') : getCommonText('clear')}`)"/>
                    </div>
                </template>

                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-if="!data.item.hasChild"
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(name_ru)="data">
                    <div v-if="data.item.par_id==null">{{ data.item.name_ru }}</div>
                    <div v-else >{{'  -  ' + data.item.name_ru }}</div>
                </template>
                <template #cell(medp_count)="data">
                    <template v-if="!data.item.hasChild && variantAttribute && getcatsForDoctors(data.item.code)">
                        <b-form-input
                                    class="text-right"
                                    :disabled="isDataLoading"
                                    :value="data.item.medp_count"
                                    @change="v => data.item.medp_count = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^[0-9]+$')"
                                    @blur="onBlur(data.item, 'medp_count', data.item.medp_count, 0)">
                        </b-form-input>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('medp_count')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div 
                        v-else-if="!data.item.hasChild && !getcatsForDoctors(data.item.code)"
                        :class="getXRowClass(variantAttribute)"
                    >x</div>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell(medp_cost)="data">
                    <template v-if="!data.item.hasChild && variantAttribute && getcatsForDoctors(data.item.code)">
                        <b-form-input
                                    class="text-right"
                                    :disabled="isDataLoading"
                                    :value="data.item.medp_cost"
                                    @change="v => data.item.medp_cost = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="onBlur(data.item, 'medp_cost', data.item.medp_cost, 2)">
                        </b-form-input>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('medp_cost')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div 
                        v-else-if="!data.item.hasChild && !getcatsForDoctors(data.item.code)"
                        :class="getXRowClass(variantAttribute)"
                    >x</div>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell(bed_amount)="data">
                    <template v-if="!data.item.hasChild && variantAttribute && !getcatsForDoctors(data.item.code)">
                        <b-form-input
                                    class="text-right"
                                    :disabled="isDataLoading"
                                    :value="data.item.bed_amount"
                                    @change="v => data.item.bed_amount = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^[0-9]+$')"
                                    @blur="onBlur(data.item, 'bed_amount', data.item.bed_amount, 0)">
                        </b-form-input>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('bed_amount')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div 
                        v-else-if="!data.item.hasChild && getcatsForDoctors(data.item.code)"
                        :class="getXRowClass(variantAttribute)"
                    >x</div>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                </template>
                <template #cell(price)="data">
                    <template v-if="!data.item.hasChild && variantAttribute && !getcatsForDoctors(data.item.code)">
                        <b-form-input
                                    class="text-right"
                                    :disabled="isDataLoading"
                                    :value="data.item.price"
                                    @change="v => data.item.price = v"
                                    @keyup.enter.exact="keyup13"
                                    @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                    @blur="onBlur(data.item, 'price', data.item.price, 2)">
                        </b-form-input>
                        <p 
                            class="form01-149-val-msg" 
                            v-if="variantAttribute && data.item.fieldsWithValMessage && data.item.fieldsWithValMessage.includes('price')"
                        >{{ getCommonText("positive") }}</p>
                    </template>
                    <div 
                        v-else-if="!data.item.hasChild && getcatsForDoctors(data.item.code)"
                        :class="getXRowClass(variantAttribute)"
                    >x</div>
                    <div v-else-if="!data.item.hasChild">{{ data.value }}</div>
                    <div v-else></div>
                    
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ isNaN(data.value) ? 0 : $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        v-if="!data.item.hasChild"
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center" v-if="!data.item.hasChild">
                        <i 
                            :title="getCommonText('clear_rec')"
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItem(`${getCommonText('clear_rec')}`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-left" colspan="6">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ isNaN(total) ? 0 : $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
            />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_149',
    components: { FilesUpdown, BudgetFormsList, FormsDownloadReprt, ModalFilesManagementNodecode, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-149',
                name_ru: 'Расчет расходов на приобретение мягкого инвентаря в учреждениях здравоохранения',
                name_kk: 'Денсаулық сақтау мекемелерiндегi жұмсақ мүкәммал сатып алуға арналған шығыстарды есептеу'
            },
            variantAttribute: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            load: true,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            catsForDoctors: ['01', '02'],
            fieldGroups: {
                gr1: ['medp_count', 'medp_cost'],
                gr2: ['bed_amount', 'price']
            }
        };
    },
    created() {
        this.$emit('setForm', this.form);
    },

    watch: {
        openDisabled() {
            this.$emit('setOpenDisabled', this.openDisabled);
        },
        categoryMode() {
            this.$emit('setCategoryMode', this.categoryMode);
        },
        progress() {
            this.$emit('setProgress', this.progress);
        }
    },

    methods: {
        changeValue(item, value) {
            this.$set(item, 'unit', value.unit);
        },

        async delete(item) {
            this.$set(item, 'form', this.form.code);
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            }
        }, // удаление данных

        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            this.$set(row, 'medp_count', 0);
                            this.$set(row, 'medp_cost', 0.0);
                            this.$set(row, 'bed_amount', 0);
                            this.$set(row, 'price', 0.0);
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    this.$set(item, 'medp_count', 0);
                                    this.$set(item, 'medp_cost', 0.0);
                                    this.$set(item, 'bed_amount', 0);
                                    this.$set(item, 'price', 0.0);
                                }
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('on_del'), error.toString());
                });
        }, // очистить значения строки

        async itemUpdate(item) {
            this.$set(item, 'medp_count', 0);
            this.$set(item, 'medp_cost', 0.0);
            this.$set(item, 'bed_amount', 0);
            this.$set(item, 'price', 0.0);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        getItem(code, list) {
            for (const item of list) {
                if (item.value.code === code) {
                    return item.value;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            await this.loadDict();

            const that = this;

            that.load = true;
            that.budgetForm.splice(0);
            const hasCildId = that.dict.reduce((acc, itm) => {
                    if (itm.par_id !== null && !acc.includes(itm.par_id)) {
                        acc.push(itm.par_id)
                    }
                    return acc
                }, []);
            for (const item of that.dict) {
                const dataItem = {
                    id: this.newRowStartId,
                    id_dict: item.id,
                    par_id: item.par_id,
                    code: item.code,
                    medp_count: 0,
                    medp_cost: 0.00,
                    bed_amount: 0,
                    price: 0.00,
                    hasChild: hasCildId.includes(item.id)
                };
                Object.defineProperty(dataItem, 'name_ru', {
                    get: () => {
                        let lng = 'ru';
                        if (this.$i18n.locale === 'kk') lng = 'kz';
                        return item[`name_${lng}`];
                    }
                });
                this.newRowStartId--;

                this.$set(dataItem, 'files', 0);

                try {
                    this.load = true;
                    const response = await fetch(`/api-py/get-new-row-files-form/${dataItem.id}/${JSON.stringify(this.header)}`);
                    const emptyRowFiles = await response.json();
                    this.$set(dataItem, 'row_files', emptyRowFiles);
                } catch (error) {
                    this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
                }

                if (item.id === 14 || item.id === 17) {
                    Object.defineProperty(dataItem, 'total', {
                        get: function () {
                            return that.reSum(dataItem, 'total');
                        }
                    });
                } else {
                    Object.defineProperty(dataItem, 'total', {
                        get: () => {
                            const medCount = this.safeDecimal(this.catsForDoctors.includes(dataItem.code) ? dataItem.medp_count : 0);
                            const medpCost = this.safeDecimal(this.catsForDoctors.includes(dataItem.code) ? dataItem.medp_cost : 0);
                            const bedAmount = this.safeDecimal(this.catsForDoctors.includes(dataItem.code) ? 0 : dataItem.bed_amount);
                            const price = this.safeDecimal(this.catsForDoctors.includes(dataItem.code) ? 0 : dataItem.price);
                            const medpTotalCost = medCount.mul(medpCost);
                            const beTotalPrice = bedAmount.mul(price);
                            const sumCostPrice = medpTotalCost.plus(beTotalPrice);
                            const total = sumCostPrice.div(1000).toDecimalPlaces(2).toNumber();
                            return total;
                        }
                    });
                }
                that.budgetForm.push(dataItem);
            }
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                if (response.status === 200) {
                    values = await response.json();
                    if (values.length === 0) {
                        that.load = false;
                        return;
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.ward_type) {
                        return item;
                    }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].medp_count = val.medp_count;
                    itemExist[0].medp_cost = val.medp_cost;
                    itemExist[0].bed_amount = val.bed_amount;
                    itemExist[0].price = val.price;
                    itemExist[0].files = val.files;
                    itemExist[0].row_files = val.row_files;
                }
            });

            this.budgetForm.forEach(itm => this.dataValidation(itm));

            that.load = false;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/ward_types/');
                this.dict = await response.json();
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict()`, error.toString());
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        noAbc: function (event) {
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            let isHasSavedItem = false;
            let isHasItemWithData = false;

            for (const row of this.budgetForm) {
                if (!this.isValidationPassed(row)) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'ward_type', row.code);

                let dataFields = this.fieldGroups.gr2;
                let zeroFields = this.fieldGroups.gr1;
                if (this.catsForDoctors.includes(row.code)) {
                    dataFields = this.fieldGroups.gr1;
                    zeroFields = this.fieldGroups.gr2;
                }

                dataFields.forEach(field => this.$set(item, field, Number(row[field])));
                zeroFields.forEach(field => this.$set(item, field, 0));

                this.$set(item, 'row_files', row.row_files);

                values.push(item);
                if (item.id > 0) isHasSavedItem = true;
                let sum = 0;
                dataFields.forEach(field => sum += item[field]);
                if (sum > 0) isHasItemWithData = true;
            }
            const isTableVirgin = !isHasSavedItem && !isHasItemWithData;
            if (!isTableVirgin && values.length && this.variantAttribute) {
                this.save(values);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        isValidationPassed(row) {
            if ('fieldsWithValMessage' in row) {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('bad_data'));
                
                return false;
            }
            return true;
        },

        reSum(parent, field) {
            let sum = this.safeDecimal(0);
            this.budgetForm.forEach(row => {
                if (row.par_id === parent.id_dict) {
                    sum = sum.plus(parseFloat(row[field]));
                }
            });
            return sum.toDecimalPlaces(2).toNumber();
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : `/api-py/save_brform${this.form.code.replace('-', '_')}/`
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.selectAll = false;
                    this.deletingAgreementTotalResultHandler(result);
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    await this.loadDatas();
                } else {
                    this.selectAll = false;
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        getcatsForDoctors(rowCode) {
            return this.catsForDoctors.includes(rowCode)
        },

        getXRowClass(val) {
            return val ? 'text-right' : 'text-left';
        },

        onBlur(item, fieldName, val, digits) {
            this.inputFixed(item, fieldName, val, digits);
            this.dataValidation(item);
        },

        dataValidation(item) {
            let linkedFields = [];
            if (this.catsForDoctors.includes(item.code)) {
                linkedFields = this.fieldGroups.gr1;
            } else linkedFields = this.fieldGroups.gr2;
            
            let valuesSum = 0
            const zeroFields = []
            linkedFields.forEach(field => {
                const decVal = this.safeDecimal(Math.abs(item[field]));
                const roundedVal = decVal.toDecimalPlaces(2).toNumber();
                if (roundedVal === 0) zeroFields.push(field);
                valuesSum += roundedVal;
            }, 0);

            const isMessageNeeded = valuesSum !== 0 && zeroFields.length > 0;
            if (isMessageNeeded) {
                this.$set(item, 'fieldsWithValMessage', zeroFields);
                return 
            } else {
                delete item.fieldsWithValMessage;
            }
        }

    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.par_id === null) {
                    sum += parseFloat(row.total);
                }
            }
            return Math.ceil(sum);
        },

        getFormText() {
            return this.setFormText('form_01_149.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('org_name')
                    },
                    {
                        key: 'medp_count',
                        label: this.getFormText('med_pos_number')
                    },
                    {
                        key: 'medp_cost',
                        label: this.getFormText('annual_med_pos_cost')
                    },
                    {
                        key: 'bed_amount',
                        label: this.getFormText('bed_count')
                    },
                    {
                        key: 'price',
                        label: this.getFormText('annual_bed_budget')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('soft_equip_cost')
                    },

                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },

        isDataLoading() {
            return this.progress < 100 || this.isLoad;
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
    .form01-149-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}
</style>